<template>
	<div class="OrderDetail">
		
		<div class="Top">
			<div class="Left">
				订单详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$Jump('/my/order/list')">返回订单列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="Status">
			<div class="Step">
				<el-steps :active="OrderStepActive">
				  <el-step title="下 单" icon="el-icon-s-goods"></el-step>
				  <el-step title="支 付" icon="el-icon-s-finance"></el-step>
				  <el-step title="发 货" icon="el-icon-s-promotion"></el-step>
				  <el-step title="收 货" icon="el-icon-s-claim"></el-step>
				   <el-step title="完 成" icon="el-icon-s-help"></el-step>
				</el-steps>
			</div>
		</div>
		
		<div class="Detail">
			<div class="List Title">
				<span class="C0"></span>
				<span class="C1">商品</span>
				<span class="C2">属性</span>
				<span class="C3">单价</span>
				<span class="C4">数量</span>
				<span class="C5">小计</span>
				<span class="C6">操作</span>
			</div>
			
			<div class="List" v-for="(child,childI) in Order.Children" :key="childI">
				<a  :href="'/#/goods/detail/'+child.GoodsId" target="_blank" class="C0">
					<img :src="child.GoodsThumb" />
				</a>
				<a class="C1" :href="'/#/goods/detail/'+child.GoodsId" target="_blank">
					<template v-if="Order.IsPt === 1">[拼团]</template>
					[{{Order.Shop.Name}}] {{child.GoodsName}}</a>
				<span class="C2">{{child.SkuName}}</span>
				<span class="C3">{{child.Price}}</span>
				<span class="C4">{{child.Num}}</span>
				<span class="C5">{{child.TotalFee}}</span>
				<span class="C6">
					<el-button v-if="child.Status == 80" @click="$Jump('/my/order/appeal_new/'+child.Id)" type="danger" size="mini">申诉</el-button>
					<el-button v-else-if="child.Status >= 90 && child.Status < 100" @click="$Jump('/my/order/appeal/'+child.AppealId)" type="danger" size="mini">查看申诉</el-button>
					<li style="margin-top: 3px;"  v-if="child.Status >= 80">
						<el-button v-if="child.IsComment == false" @click="dialogOpen(child)" type="primary" size="mini">评价</el-button>
						<em  v-if="child.IsComment == true">已评价</em>
					</li>
				</span>
			</div>
			
			<div class="Count">
				<li><span>商品总额：</span><em>¥{{Order.TotalFee}}</em></li>
				<li><span>运费：</span><em>¥0.00</em></li>
				<li><span>订单总额：</span><em>¥{{Order.TotalFee}}</em></li>
				<li><span>订单状态：</span><em>{{Order.StatusName}}</em></li>
				<li v-if="Order.IsPt === 1"><span>拼团状态：</span><em>{{Order.StatusPtName}}</em></li>
				<li style="margin-top: 10px;" v-if="Order.Status == 50">
					
					<el-button @click="OrderCancel()" type="info" size="mini">取消</el-button>
					
					<el-button @click="$Jump('/order/prepay/'+Order.Id)" type="danger" size="mini">去付款</el-button></li>
				
				
				<li style="margin-top: 10px;"  v-if="Order.Status >= 70 && Order.Status < 80">
					<span>
						
					</span>
					<em>
						<el-button size="medium" type="primary" @click="ExpressCheck()">确认收货</el-button>
					</em>
					<p style="padding: 0px;font-size: 0.8rem;color: rgba(0,0,0,0.3);margin: 0px;margin-top: 5px;">
						如有货物问题，可在确认收货后发起申诉
					</p>
				</li>
			</div>
			
		</div>
		
		<div class="Express">
			
			<div class="Address">
				
				<h3>收货人信息</h3>
				<template v-if="Order.Address != undefined && Order.Address != null">
				
				<li><span>收货人：</span>{{Order.Address.Addressee}}</li>
				<li><span>地址：</span>{{Order.Address.Province}}{{Order.Address.City}}{{Order.Address.Region}}{{Order.Address.Town ? Order.Address.Town:''}}{{Order.Address.Detail}}</li>
				<li><span>手机号码：</span>{{$StrStar(Order.Address.Mobile)}}</li>
				
				<h3 class="Invoice">发票信息</h3>
				<template v-if="Order.Invoice">
					<template v-if="Order.Invoice.Type == 'special'">
						<li><span>发票类型：</span>增值税专票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li><span>税号：</span>{{Order.Invoice.DutyParagraph}}</li>
						<li><span>单位开户银行：</span>Order.Invoice.CompanyBank</li>
						<li><span>单位银行账号：</span>Order.Invoice.CompanyBankCardId</li>
						<li><span>单位联系地址：</span>Order.Invoice.CompanyAddress</li>
					</template>
					<template v-if="Order.Invoice.Type == 'normal'">
						<li><span>发票类型：</span>增值税普票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li><span>税号：</span>{{Order.Invoice.DutyParagraph}}</li>
						<li v-if="Order.Invoice.Mail"><span>电子发票邮箱：</span>{{Order.Invoice.Mail}}</li>
					</template>
					<template v-if="Order.Invoice.Type == 'person'">
						<li><span>发票类型：</span>增值税普票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li v-if="Order.Invoice.Mail"><span>电子发票邮箱：</span>{{Order.Invoice.Mail}}</li>
					</template>
				</template>
				<template v-else>
					<li>未设置发票，可联系客服补开</li>
				</template>
				
				</template>
				
			</div>
			
			<div class="Dynamic">
				<h3>
					快递动态
					<span v-if="Order.Express">{{Order.Express.ExpressCompanyName}}<em>(单号:{{Order.Express.ExpressCode}})</em></span>
				</h3>
				
				<div class="Info">
				  <vuescroll :ops="vuescroll_ops" ref="vs">
				  	  <div v-if="Order.Express && Order.Express.Dynamic">
						  <el-steps :space="'40px'" direction="vertical" :active="1">
						    <el-step :title="d.ftime + ' ' + d.context" :key="dI" v-for="(d,dI) in Order.Express.Dynamic"></el-step>
						  </el-steps>
					  </div>
				    </vuescroll>
				</div>
				
			</div>
			
		</div>
		
		<el-dialog
		v-if="DialogOrderDetail != null"
		  title="商品评价"
		  :visible="true"
		  :close-on-click-modal="false"
		  width="350px"
		  :before-close="handleClose">
		  <div>
			  <span style="color: rgba(0,0,0,0.4);">评分</span>
			  <div style="margin-top: 5px;">
				  <el-rate
				      v-model="CommentRate"
				      :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
				    </el-rate>
			  </div>
		  </div>
		  <div style="margin-top: 10px;">
			  <span style="color: rgba(0,0,0,0.4);">评价</span>
			  <div style="margin-top: 5px;">
				  <el-input type="textarea" v-model="CommentContent"></el-input>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="handleClose">取 消</el-button>
		    <el-button type="primary" @click="NewGoodsComment">确 定</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Steps,Step,Dialog,Rate} from 'element-ui'
	import vuescroll from 'vuescroll'
	export default {
	  name: 'MyOrderDetail',
	  props: {
	  },
	  data() {
	      return {
			  Order:null,
			  OrderStepActive:1,
			  vuescroll_ops: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#000000',
			  		opacity: 0.2,
			  	}	
			  },
			  DialogOrderDetail:null,
			  CommentRate:null,
			  CommentContent:'',
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-steps':Steps,
		'el-step':Step,
		'vuescroll':vuescroll,
		'el-dialog':Dialog,
		'el-rate':Rate,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('进入订单的路径不对，请退出')
			return
		}
		this.Order = {
			Id:this.$route.params.Id
		}
		this.GetDetail()
	  },
	  methods:{
		  dialogOpen(_orderDetail){
			this.DialogOrderDetail = JSON.parse(JSON.stringify(_orderDetail))  
			this.CommentContent = ''
			this.CommentRate = null
		  },
		  handleClose(){
			  this.DialogOrderDetail = null
		  },
		  ExpressCheck(){
			  this.$confirm('是否确认收货？收货后如有货物问题可发起申诉/退换')
				.then(() => {
				  this.OrderUpdate()
				})
		  },
		  OrderUpdate(){
			  let data = {
				Service:'Order',
				Class: 'Order',
				Action: 'Update',
				Id:this.Order.Id,
				Status:80,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  				
					
					if(res.ErrorId != 0){
						this.$message.error(res.Msg)
						return
					}
					this.$message("确认收货成功")
					this.GetDetail()
			  				
			  })
			  .catch(function (response) {
			  		this.$message('网络请求快递信息错误')
			  })
		  },
		  OrderCancel(){
			  let data = {
				Service:'Order',
				Class: 'Order',
				Action: 'Cancel',
				Id:this.Order.Id,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
							
				if(res.ErrorId != 0){
					this.$message.error(res.Msg)
					return
				}
				this.$message("取消成功")
				this.GetDetail()
							
			  })
			  .catch(function (response) {
					this.$message('网络请求快递信息错误')
			  })
		  },
		  NewGoodsComment(){ //提交商品评价
			  let data = {
					Service:'Order',
					Class: 'GoodsComment',
					Action: 'Add',
					Id:this.Order.Id,
					GoodsId:this.DialogOrderDetail.GoodsId,
					Score:this.CommentRate,
					Content:this.CommentContent,
					OrderDetailId:this.DialogOrderDetail.Id,
					
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  							
					if(res.ErrorId != 0){
						this.$message.error(res.Msg)
						return
					}
					this.handleClose()
					this.$message.success("被选中商品的评价已完成")
					this.GetDetail()
			  							
			  })
			  .catch(function (response) {
			  		this.$message('网络请求错误')
			  })
		  },
		  GetDetail(){
		  
		  		let data = {
		  			Service:'Order',
		  			Class: 'Order',
		  			Action: 'Information',
		  			Id:this.Order.Id
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.Order = res.Data
					this.Order.Children.forEach(item => {
						item.IsComment = false
					})
					
					if(this.Order.Status >= 50 && this.Order.Status < 60){
						this.OrderStepActive = 1
					}else if(this.Order.Status >= 60 && this.Order.Status < 70){
						this.OrderStepActive = 2
					}else if(this.Order.Status >= 70 && this.Order.Status < 80){
						this.OrderStepActive = 3
					}else if(this.Order.Status == 80){
						this.OrderStepActive = 4
					}else if(this.Order.Status > 80){
						this.OrderStepActive = 5
					}
					
					this.GetCommentInfo()
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCommentInfo(){
			  
			  let data = {
			  	Service:'Goods',
			  	Class: 'GoodsComment',
			  	Action: 'List',
			  	OrderId:this.Order.Id
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
				if(res.Data.GoodsCommentList.length == 0){
					return
				}
				
			  	this.Order.Children.forEach(item => {
					
					for(var i=0;i<res.Data.GoodsCommentList.length;i++){
						
						if(res.Data.GoodsCommentList[i].GoodsId == item.GoodsId){
							item.IsComment = true
							console.log('锁定了商品',item)
						}
						
					}
					
				})
				this.$forceUpdate()
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
	  }
	}
</script>

<style scoped>
	.OrderDetail{
		margin: 20px;
		padding: 20px;
	}
	
	.OrderDetail .Top{
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		padding-bottom: 20px;
	}
	.OrderDetail .Top .Left{
		
	}
	.OrderDetail .Top .Right{
		flex: 1;
		text-align: right;
		color: #999999;
	}
	
	.Status{
		width: 100%;
		height: 100px;
		background-color: #ffffff;
		border-bottom: 1px solid rgba(0,0,0,0.06);
		display: flex;
		justify-content: center;
		margin-top: 20px;
		padding-top: 40px;
	}
	.Step{
		width: 80%;
	}
	
	.Detail{
		background-color: #FFFFFF;
		margin-top: 20px;
		padding: 30px 20px;
		
	}
	.Detail .List{
		display: flex;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(0,0,0,0.05);
		align-items: center;
	}
	.Detail .Title{
		color: rgba(0,0,0,0.3);
	}
	.List .C0,.List .C1,.List .C2,.List .C3,.List .C4,.List .C5,.List .C6{
		width: 120px;
	}
	.List .C0 img{
		height: 80px;
	}
	.List .C1{
		flex: 1;
	}
	.List .C1:hover{
		text-decoration: underline;
	}
	.List .C2{
		width: 140px;
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.Count{
		text-align: right;
	}
	.Count span{
		font-size: 0.9rem;
		color: rgba(0,0,0,0.4);
	}
	
	.Express{
		background-color: #FFFFFF;
		margin-top: 20px;
		padding: 30px 20px;
		display: flex;
	}
	.Express .Address{
		width: 400px;
		border-right: 1px solid rgba(0,0,0,0.1);
		padding-right: 20px;
		margin-right: 20px;
	}
	.Express .Address h3{
		color: #999999;
	}
	.Express .Address li{
		margin-bottom: 5px;
		display: flex;
	}
	.Express .Address li span{
		display: inline-block;
		width: 100px;
	}
	
	.Express .Invoice{
		margin-top: 30px;
	}
	
	.Express .Dynamic{
		flex: 1;
	}
	.Express .Dynamic .Info{
		max-height: 200px;
		height: 200px;
		background-color: rgba(0,0,0,0.05);
		padding: 20px;
		flex: 1;
	}
	.Express h3 span{
		float: right;
		font-weight: normal;
		font-size: 14px;
	}
</style>
